<template>
    <v-dialog
            v-model="dialog"
            persistent
            max-height="700"
            max-width="900"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn text
                   color="primary"
                   v-bind="attrs"
                   v-on="on"
                   :disabled="disabled"
            >
                <v-icon left>
                    mdi-draw
                </v-icon>
                <span v-if="inspectionSelected.signaturesPlaced && inspectionSelected.signaturesPlaced.length > 0 || (signature.file && signature.description)">{{ $t('inspections.buttons.editSignature') }}</span>
                <span v-else>{{ $t('inspections.buttons.addSignature') }}</span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline d-flex justify-space-between secondary t-bw-secondary--text">
                <span>{{ $t('inspections.buttons.addSignature') }}</span>
                <v-icon color="t-bw-secondary" @click="dialog = !dialog">
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text class="pa-6">
                <v-container>
                    <v-row>
                        <v-col cols="12" style="border-radius: 8px;">
                            <span class="d-flex justify-end" style="position: relative">
                               <v-btn small
                                      tile
                                      :elevation="0"
                                      color="gray"
                                      style="border-radius: 1.5em 0 0 1.5em;"
                                      class="mr-1 mb-4" @click="clear()">
                                 <v-icon small color="neuter" left>mdi-cached</v-icon>
                                 <span class="neuter--text">{{ $t('common.buttons.clean') }}</span>
                               </v-btn>
                              <v-btn small
                                     tile
                                     :elevation="0"
                                     color="gray"
                                     style="border-radius: 0 1.5em 1.5em 0;"
                                     @click="undo()">
                                <v-icon small color="neuter" left>mdi-undo-variant</v-icon>
                                <span class="neuter--text">{{ $t('common.buttons.undo') }}</span>
                              </v-btn>
                              <!--<v-btn small
                                     :disabled="!(refers && refers.length > 0)"
                                     tile
                                     :elevation="0"
                                     color="gray"
                                     style="border-radius: 0 1.5em 1.5em 0;"
                                     @click="remove(ref, index)">
                                  <v-icon small color="neuter" left>mdi-minus-circle-outline</v-icon>
                                  <span class="neuter--text">Remove</span>
                              </v-btn>-->
                            </span>
                            <v-card outlined elevation="0" class="d-flex justify-center align-center flex-nowrap">
                                <vue-signature ref="signature"
                                               :sigOption="options"
                                               :h="'400px'"
                                               :disabled="false"
                                />
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                          <v-form ref="form" v-model="valid">
                            <v-textarea onkeypress="return (event.charCode != 34)"
                                        v-on:keyup.86="replaceDescription" v-on:keyup.ctrl.86="replaceDescription"
                                        no-resize
                                        color="primary"
                                        :label="$t('inspections.labels.leave_description')"
                                        v-model="description"
                                        auto-grow
                                        counter
                                        outlined
                                        dense
                                        :rules="maxRules(1000)"
                            />
                          </v-form>
                        </v-col>
                        <v-col cols="12">
                            <v-fade-transition>
                                <v-alert
                                    v-if="emptySignature"
                                    dense
                                    border="left"
                                    text
                                    type="error"
                                    icon="mdi-alert-octagon-outline"
                                >
                                    <template v-slot:close>
                                        <v-icon @click="closeAlert" color="error">
                                            mdi-close
                                        </v-icon>
                                    </template>
                                    <span v-html="emptySignature" />
                                </v-alert>
                            </v-fade-transition>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <!--<v-btn small
                                   tile
                                   :elevation="0"
                                   color="gray" class="mr-1"
                                   style="border-radius: 1.5em 0 0 1.5em;"
                                   @click="save()">
                                <v-icon small color="neuter" left>mdi-content-save-outline</v-icon>
                                <span class="neuter--text">Save</span>
                            </v-btn>-->
                            <!--<v-btn small
                                   tile
                                   :elevation="0"
                                   color="gray"
                                   style="border-radius: 0 1.5em 1.5em 0;"
                                   @click="handleDisabled()">
                                <v-icon small color="neuter" left>{{ icon }}</v-icon>
                                <span class="neuter--text">{{ text }}</span>
                            </v-btn>-->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="pa-6">
              <v-spacer/>
              <v-btn  color="neuter"
                      text
                      @click="closeDialogSignatures()"
                      :disabled="!signature.file"
              >
                {{ $t('inspections.buttons.discardChanges') }}
              </v-btn>
              <v-btn color="primary"
                     elevation="0"
                     class="t-bw-primary--text"
                     :disabled="!valid"
                     @click="save()"
              >
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import vueSignature from "vue-signature"
import {mapGetters} from "vuex";

export default {
    name: "VueSignature",
    components: {
        vueSignature
    },
    props: {
        disabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            sign: null,
            loadingSing: false,
            emptySignature: null,
            dialog: false,
            iconLock: false,
            icon: 'mdi-lock-open-outline',
            text: 'lock',
            description: '',
            oldSignature: {},
            options: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "#FFFFFF"
            },
            valid: false
            //disabled: false,
        };
    },

    created() {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            maxRules: 'general/maxRules',
        }),
        inspectionSelected: {
            get() {
                return this.$store.getters['inspections/inspection'];
            },
            set(val) {
                this.$store.commit('inspections/SET_INSPECTION', val)
            }
        },
        signature: {
            get() {
                return this.$store.getters['signature/GET_SIGNATURE'];
            },
            set(val) {
                this.$store.commit('signature/SIGNATURE', val)
            }
        },
        dataImage() {
            return this.inspectionSelected.signaturesPlaced && this.inspectionSelected.signaturesPlaced.length > 0 ? this.inspectionSelected.signaturesPlaced[0].signature.s3_url : ''
        },
    },
    watch: {
        description() {
            this.$set(this.signature, 'description', this.description)
        }
    },
    methods: {
        initialize() {
            let _this = this;
            _this.oldSignature = _this.signature

            if (_this.inspectionSelected.signaturesPlaced.length > 0) {
                _this.description = _this.inspectionSelected.signaturesPlaced[0].description;
            }
        },
        closeAlert() {
            let _this = this;
            _this.emptySignature = null;
            _this.error = false
        },
        save() {
            let _this = this;

            if (!_this.$refs.signature.isEmpty()) {
                let png = _this.$refs.signature.save();
                let file = _this.createFileForPNG(png, 'signature');

                _this.signature.name = file.name;

                if (_this.description === '') {
                    _this.emptySignature = _this.$t('inspections.rules.description');
                    return
                }
                _this.signature.description = _this.description;
                _this.signature.file = file;
                _this.signature.base64 = png;
                _this.emptySignature = false;

                _this.dialog = false;
            } else {
                _this.emptySignature = _this.$t('inspections.rules.signature');
            }
        },
        createFileForPNG(svg, index) {
            let _this = this;

            let file = _this.dataURLtoFile(svg, index);

            let dt = new Date().getTime();
            let uuid = 'agdoc_' + file.name +'xxxxxx'.replace(/[xy]/g, (c) => {
                let r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });

            return new File([file], uuid + '.png', {
                type: file.type,
                lastModified: file.lastModified,
            })
        },
        dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type:mime});
        },
        clear() {
            let _this = this;
            _this.$refs.signature.clear();

            _this.$set(_this.signature, 'file', null);
            _this.$set(_this.signature, 'base64', null);
            _this.signature.description = '';
            _this.signature.name = ''
        },
        undo() {
            let _this = this;
            _this.$refs.signature.undo();
        },
        closeDialogSignatures () {
            let _this = this;
            if (_this.inspectionSelected.signaturesPlaced.length > 0) {
                _this.dialog = false;
                _this.clear();
                _this.description = _this.inspectionSelected.signaturesPlaced[0].description;
                if (_this.signature.file) {
                    _this.$store.commit('signature/SIGNATURE_ORIGINAL_STATE');
                }
                return
            }
            _this.clear();
            _this.description = '';
            _this.$store.commit('signature/SIGNATURE_ORIGINAL_STATE');
            _this.dialog = false;
        },

        getBase64Image(img) {
            let canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            /*.replace(/^data:image\/(png|jpg);base64,/, "")*/
            return canvas.toDataURL("image/png");
        },
        fromDataURL() {
            let _this = this;
            const url = _this.inspectionSelected.signaturesPlaced &&
            _this.inspectionSelected.signaturesPlaced.length > 0
                ? _this.inspectionSelected.signaturesPlaced[0].signature.s3_url
                : null;

            if (url) {
                let img = new Image();
                img.crossOrigin = "Anonymous";
                img.setAttribute('crossorigin', 'anonymous');
                img.src = url;

                _this.sign =  _this.getBase64Image(img);
            }
        },
        handleDisabled() {
            let _this = this;
            if (!_this.iconLock) {
                _this.iconLock = true
                _this.icon = 'mdi-lock-outline';
                _this.text = 'unlock'
                this.$set(_this.options, 1, '#4f4f4f');
            } else if (_this.iconLock) {
                _this.iconLock = false
                _this.icon = 'mdi-lock-open-outline';
                _this.text = 'lock'
                this.$set(_this.options, 1, '#ffffff');
            }
            _this.disabled = !_this.disabled;
        },
        replaceDescription(event){
            this.description =  event.currentTarget.value.toString().replaceAll('"','')
        },
    }
}
</script>

<style scoped>

</style>